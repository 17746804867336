import React from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import CheckboxesLoans from '../../widgets/CheckboxLoans/CheckboxLoans';
import { Box } from '@mui/system';
import { LoansSearchType } from '../Loans/useHooks';

const ITEM_HEIGHT = 48;

interface Props {
  isMobile: boolean;
  loansSearch: LoansSearchType;
}

export default function SearchLoansBar({ isMobile, loansSearch }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ marginLeft: isMobile ? 'auto' : null }}>
      {' '}
      {isMobile && (
        <>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            slotProps={{
              paper: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
                },
              },
            }}
          >
            <MenuItem>
              <CheckboxesLoans loansSearch={loansSearch} />
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Typography component="span">ок</Typography>
            </MenuItem>
          </Menu>
        </>
      )}
      {!isMobile && <CheckboxesLoans loansSearch={loansSearch} />}
    </Box>
  );
}
