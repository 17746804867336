import { createSlice } from "@reduxjs/toolkit";
export interface IActiveUser {
  login: boolean;
  user?: {
    _id: string;
    personal: {
      userName: {
        firstName: string;
        lastName: string;
        middlename: string;
      };
    };
    contacts: {
      email: {
        value: string;
        confirm: boolean;
      };
    };
    rules: [];
    passwordId: string;
  };
  token?: string;
}

const initialState = {
  login: false,
  user: {
    _id: "",
    personal: {
      userName: {
        firstName: "",
        lastName: "",
        middlename: "",
      },
    },
    contacts: {
      email: {
        value: "",
        confirm: false,
      },
    },
    rules: [],
    passwordId: "",
  },
  token: "",
};

export const counterSlice = createSlice({
  name: "activeUser",
  initialState,
  reducers: {
    setActiveUser: (state, action) => {
      state.login = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    removeActiveUser: (state) => {
      state.login = false;
      state.user = initialState.user;
      state.token = initialState.token;
    },
  },
});

export const { setActiveUser, removeActiveUser } = counterSlice.actions;

export default counterSlice.reducer;
