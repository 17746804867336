import { IActiveUser } from '../reducer/user/activeUser';
import { IBriefcases } from '../reducer/briefcases/briefcase';
import { iLoansInitState } from '../reducer/loans/loans';
import { IPurseState } from '../reducer/purses/purses';

interface RootState {
  activeUser: IActiveUser;
  briefcases: IBriefcases;
  loans: iLoansInitState;
  purses: IPurseState;
}

export const selectActiveUser = (state: RootState) => state.activeUser;
export const selectBriefcases = (state: RootState) =>
  state.briefcases.briefcases;

export const selectLoans = (state: RootState) => state.loans.loans;
export const selectLoansPaginate = (state: RootState) => state.loans.paginate;

export const selectPurse = (state: RootState) => state.purses.purses;
