import React from 'react';
import { Container } from '@mui/system';
import ConfirmComponent from './Confirm.component';
import { Box } from '@mui/material';
const ConfirmScreen = () => {
  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          paddingTop: '30px',
        }}
      >
        <ConfirmComponent />
      </Box>
    </Container>
  );
};

export default ConfirmScreen;
