import { apiAlliance } from './constants';
import { getCookie } from 'typescript-cookie';
import { LoansStatusEnum } from '../Components/Loans/constants';
import { LoansSearchType } from '../Components/Loans/useHooks';

export type LoanProposalType = {
  percent: number;
};

export type LoanProposalFiledType = {
  actionType: string;
  lenderId: string;
  percent: number;
  reserveAmountId: string;
  _id: string;
};

export type LoanType = {
  _id: string;
  idName: string;
  loanBorrowerId: string;
  loanAmount: number;
  currency: string;
  loanTerm: number;
  amountPercentage: number;
  paymentInterval: number;
  activityPeriod: number;
  status: LoansStatusEnum;
  dateCreate: string;
  proposals: LoanProposalFiledType[];
};

export interface ILoanCreateType {
  activityPeriod: number;
  loanAmount: number;
  loanTerm: number;
  paymentInterval: number;
}

export type ReqLoans = {
  limit?: number | null;
  offset?: number | null;
  page?: number | null;
};

export type paginateResponse = {
  limit: number;
  totalDocs: number;
  offset: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number;
  nextPage: number;
};

export const apiGetByLoanId = (accToken: string, idName: string) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${accToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  return fetch(`${apiAlliance}/loans/${idName}`, requestOptions)
    .then(async (resp): Promise<LoanType> => resp.json())
    .catch((error) => {
      return error;
    });
};

export const apiGetLoans = (
  reqLoans?: ReqLoans,
  loansSearchType?: LoansSearchType['searchParam'],
) => {
  const accToken = getCookie('accToken');
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${accToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };
  const paramsObj = reqLoans && { ...reqLoans, ...loansSearchType };
  // @ts-ignore // Не нравиться типы(
  const searchParams = reqLoans ? `?${new URLSearchParams(paramsObj)}` : '';

  return fetch(`${apiAlliance}/loans${searchParams}`, requestOptions)
    .then(async (resp): Promise<LoanType[]> => resp.json())
    .catch((error) => {
      return error;
    });
};

export const apiAddProposalInLoans = (
  idName: string,
  proposal: LoanProposalType,
) => {
  const accToken = getCookie('accToken');
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${accToken}`);

  const row = JSON.stringify(proposal);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: row,
  };

  return fetch(`${apiAlliance}/loans/proposal/${idName}`, requestOptions)
    .then(async (resp): Promise<LoanType> => resp.json())
    .catch((error) => {
      return error;
    });
};

export const apiAddLoan = async (newLoan: ILoanCreateType) => {
  const accToken = getCookie('accToken');
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${accToken}`);

  const row = JSON.stringify(newLoan);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: row,
  };

  try {
    const resp = await fetch(`${apiAlliance}/loans`, requestOptions);
    return await resp.json();
  } catch (error) {
    return error;
  }
};

export const apiAgreementLoan = async (idName: string) => {
  const accToken = getCookie('accToken');
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  myHeaders.append('Authorization', `Bearer ${accToken}`);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
  };

  try {
    const resp = await fetch(
      `${apiAlliance}/loans/agreement/${idName}`,
      requestOptions,
    );
    return await resp.json();
  } catch (error) {
    return error;
  }
};
