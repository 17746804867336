import React from 'react';
import ConfirmHandler from './Confirm.handler';
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material';

export interface IConfirmComponent {
  load: boolean;
  isSuccess: boolean;
  message: string;
  handleContinue: () => void;
  handleGoToMain: () => void;
}

const ConfirmComponent = (props: IConfirmComponent) => {
  return (
    <Card
      variant="outlined"
      sx={{
        gap: 2,
        borderRadius: 2,
      }}
    >
      {props.load && <CircularProgress />}
      {!props.load && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography
            component="h4"
            variant="h6"
            sx={{
              textAlign: 'center',
              paddingTop: '24px',
            }}
          >
            {props.message}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button onClick={props.handleGoToMain}>Назад</Button>
            {props.isSuccess && (
              <Button onClick={props.handleContinue}>Продолжить</Button>
            )}
          </Box>
        </Box>
      )}
    </Card>
  );
};
export default ConfirmHandler(ConfirmComponent);
