import React from 'react';
import { LoansComponentProps } from './Loans.component';
import { useDialogView, useLoanCreateDialog, useLoansSearch } from './useHooks';

const LoansHandler = (
  LoansComponent: React.ComponentType<LoansComponentProps>,
) => {
  const WithScreen = () => {
    const loansSearch = useLoansSearch();
    const { loanDialog, loans, handleSetQueryLoanId, paginate } =
      useDialogView();
    const { loanCreateDialog } = useLoanCreateDialog();

    return (
      <LoansComponent
        loanCreateDialog={loanCreateDialog}
        loanDialog={loanDialog}
        loans={loans}
        onSetQueryLoanId={handleSetQueryLoanId}
        onChangePage={loansSearch.onChangePage}
        paginate={paginate}
        loansSearch={loansSearch}
      />
    );
  };

  WithScreen.displayName = `LoansHandler(${LoansComponent.displayName || LoansComponent.name})`;

  return WithScreen;
};

export default LoansHandler;
