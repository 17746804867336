import { Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { getStatusLoan } from '../Loans/utils';
import React from 'react';
import { ElevationScroll } from '../../utils/ElevationScroll';
import { LoanType as LoanTypeDefault } from '../../api/loans';

export type LoansListType = LoanTypeDefault & {
  isSelfBorrower?: boolean;
};

interface Props {
  loans: LoansListType[];
  onSetQueryLoanId: (idName: string) => void;
}

export default function LoanList(props: Props) {
  return (
    <ElevationScroll>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {props.loans.map((loan) => {
          return (
            <div
              key={loan._id}
              onClick={() => {
                props.onSetQueryLoanId(loan.idName);
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  borderRadius: 2,
                  p: 1,
                  cursor: 'pointer',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  <Typography component="div">{loan.idName}</Typography>
                  <Typography component="div">
                    {getStatusLoan(loan.status)}
                  </Typography>
                </Box>
                {loan.isSelfBorrower && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                      Моя заявка
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                    Сумма займа: {loan.loanAmount}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                    Проценты:{' '}
                    {loan.amountPercentage
                      ? loan.amountPercentage
                      : 'Нет предложении'}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                    Срок погашения: {loan.loanTerm}
                  </Typography>
                </Box>
              </Card>
            </div>
          );
        })}
      </Box>
    </ElevationScroll>
  );
}
