import { Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment-timezone';
import React from 'react';
import { PurseHistoryActionTypeEnum } from './Briefcase.component';
import { IPurse } from '../../../../api/purse';

const purseHistoryActionName = {
  loan: 'Займ',
  commission: 'Комиссия',
  debt: 'Долг',
};

interface PursePops {
  purse: IPurse;
}

const Purse = ({ purse }: PursePops) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 2,
        p: 1,
      }}
    >
      {purse.history.map((history) => {
        return (
          <Card
            key={history._id}
            variant="outlined"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              borderRadius: 2,
              p: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                {purseHistoryActionName[history.actionName]}
              </Typography>
              {history.dateCreate && (
                <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                  {moment(history.dateCreate).utc().format('YYYY-MM-DD hh:mm')}
                </Typography>
              )}
              <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                {history.actionType === PurseHistoryActionTypeEnum.plus
                  ? '+'
                  : '-'}{' '}
                {history.amount} {purse.currency}
              </Typography>
            </Box>
          </Card>
        );
      })}
    </Box>
  );
};
export default Purse;
