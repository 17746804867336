import { Typography, Button, Pagination, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { paginateResponse } from '../../api/loans';
import LoanViewDialog, { ILoanDialogProps } from './LoanView.dialog';
import LoanCreateDialog, { ILoanCreateDialogProps } from './LoanCreate.dialog';
import LoansHandler from './Loans.handler';
import SearchLoansBar from '../SearchLoansBar/SearchLoansBar';
import LoanList, { LoansListType } from '../LoanList/LoanList';
import { LoansSearchType } from './useHooks';

export interface LoansComponentProps {
  loans: LoansListType[];
  loanDialog: ILoanDialogProps;
  loanCreateDialog: ILoanCreateDialogProps;
  onSetQueryLoanId: (idName: string) => void;
  paginate?: paginateResponse;
  onChangePage: (event: React.ChangeEvent<unknown>, page: number) => void;
  loansSearch: LoansSearchType;
}

const LoansComponent = (props: LoansComponentProps) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography component="h1" variant="h4">
          Займы
        </Typography>
        <Button onClick={props.loanCreateDialog.handleOpenLoanCreate}>
          Взять займ
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <SearchLoansBar isMobile={isMobile} loansSearch={props.loansSearch} />
        <Box
          sx={{
            width: '100%',
          }}
        >
          <LoanList
            loans={props.loans}
            onSetQueryLoanId={props.onSetQueryLoanId}
          />
        </Box>
      </Box>
      {props.paginate && props.paginate.totalPages > 1 && (
        <Box sx={{ margin: 'auto' }}>
          <Pagination
            count={props.paginate?.totalPages}
            page={props.paginate.page}
            onChange={props.onChangePage}
          />
        </Box>
      )}
      <LoanViewDialog
        {...props.loanDialog}
        loan={props.loans.find(
          (loan) => props.loanDialog.openLoanId === loan.idName,
        )}
      />
      <LoanCreateDialog {...props.loanCreateDialog} />
    </>
  );
};

export default LoansHandler(LoansComponent);
