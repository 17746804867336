import React from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { setActiveUser } from '../../reducer/user/activeUser';
import { callAuthSignin } from '../../api/auth';
import { useNavigate } from 'react-router';
import { setCookie } from 'typescript-cookie';
import { UserSignIn } from '../../Interfice/user';
import { loginValidate } from './login.validate';
import { ILogin } from './Login.component';

const ScreenHandler = (LoginScreen: React.ComponentType<ILogin>) => {
  const WithScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = (
      values: UserSignIn,
      { setSubmitting, setErrors }: any,
    ) => {
      setErrors({});
      callAuthSignin(values)
        .then((response) => {
          let formError = '';
          if (typeof response.token === 'string') {
            setCookie('accToken', response.token, { expires: 365 });
            dispatch(setActiveUser(response));
            navigate('/home');
          } else if (response.statusCode >= 400 && response.statusCode <= 499) {
            formError = 'Не правильный email или пароль';
          } else if (response.statusCode >= 500) {
            formError = 'Ошибка сервера. Попробуйте позже';
          } else {
            formError = 'Не известная ошибка. Попробуйте позже';
          }

          setErrors({ formError });
        })
        .catch(() => {
          setErrors({ formError: 'Не известная ошибка. Попробуйте позже' });
        })
        .finally(() => {
          setSubmitting(false);
        });
    };
    return (
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={loginValidate}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <LoginScreen
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        )}
      </Formik>
    );
  };
  WithScreen.displayName = `withScreen(${LoginScreen.displayName || LoginScreen.name})`;
  return WithScreen;
};

export default ScreenHandler;
