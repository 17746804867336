import { configureStore } from '@reduxjs/toolkit';
import activeUserReducer from './reducer/user/activeUser';
import briefcaseReducer from './reducer/briefcases/briefcase';
import loansReducer from './reducer/loans/loans';
import purseReducer from './reducer/purses/purses';

export default configureStore({
  reducer: {
    activeUser: activeUserReducer,
    briefcases: briefcaseReducer,
    loans: loansReducer,
    purses: purseReducer,
  },
});
