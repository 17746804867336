import React from 'react';
import { IBriefcaseComponentProps } from './Briefcase.component';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveUser,
  selectBriefcases,
  selectPurse,
} from '../../../../useSelector';
import { getCookie } from 'typescript-cookie';
import {
  apiCreateBriefcase,
  apiGetBriefcaseById,
} from '../../../../api/briefcase';
import { setBriefcases } from '../../../../reducer/briefcases/briefcase';
import { apiGetPurseById } from '../../../../api/purse';
import { setPurse } from '../../../../reducer/purses/purses';

const BriefcaseHandler = (
  BriefcaseComponent: React.ComponentType<IBriefcaseComponentProps>,
) => {
  const WithScreen = () => {
    const activeUser = useSelector(selectActiveUser);
    const pursesSelect = useSelector(selectPurse);
    const briefcasesSelect = useSelector(selectBriefcases);
    const accToken = getCookie('accToken');
    const dispatch = useDispatch();

    React.useEffect(() => {
      if (activeUser.login && !briefcasesSelect.length && accToken) {
        apiGetBriefcaseById(accToken).then((fetchBriefcases) => {
          if (fetchBriefcases.length) {
            dispatch(setBriefcases(fetchBriefcases));

            return;
          }
          apiCreateBriefcase(accToken).then((createBriefcases) => {
            if (createBriefcases._id) {
              dispatch(setBriefcases([createBriefcases]));

              return;
            }
          });
        });
      }
    }, [accToken, activeUser.login, dispatch, briefcasesSelect.length]);

    React.useEffect(() => {
      if (!accToken) {
        return;
      }
      apiGetPurseById().then((purse) => {
        if (purse._id) {
          dispatch(setPurse(purse));
        }
      });
    }, [accToken, dispatch]);

    return (
      <BriefcaseComponent briefcases={briefcasesSelect} purses={pursesSelect} />
    );
  };

  WithScreen.displayName = `BriefcaseHandler(${BriefcaseComponent.displayName || BriefcaseComponent.name})`;

  return WithScreen;
};

export default BriefcaseHandler;
