import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router';

import './App.css';
import LoginScreen from './Screen/Login';
import HeaderLayout from './Layout/Header';
import SignupScreen from './Screen/Signup';
import AccountScreen from './Screen/Account';
import HomeScreen from './Screen/Home';
import MainScreen from './Screen/Main';
import { apiRadar } from './api/radar';
import MarketsScreen from './Screen/Markets';
import ConfirmScreen from './Screen/Confirm';

declare global {
  interface Window {
    ym?(
      goalId: number,
      type: string,
      targetName?:
        | {
            clickmap?: boolean;
            trackLinks?: boolean;
            accurateTrackBounce?: boolean;
          }
        | string,
    ): void;
  }
}

function App() {
  React.useEffect(() => {
    apiRadar({
      name: 'appReady',
      radarType: 'js',
      userAgent: window?.navigator?.userAgent,
    });

    if (process.env.NODE_ENV === 'production' && window.ym) {
      window.ym(100681845, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
      });
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HeaderLayout />}>
          <Route path="/" element={<MainScreen />} />
          <Route path="/main" element={<MainScreen />} />
          <Route path="/home/:tabs?" element={<HomeScreen />} />
          <Route path="/markets/:tabs?" element={<MarketsScreen />} />
          <Route path="/account" element={<AccountScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/signup" element={<SignupScreen />} />
          <Route path="*" element={<div>404</div>} />
        </Route>
        <Route path="/confirm" element={<ConfirmScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
