import React from 'react';
import {
  TextField,
  Button,
  FormLabel,
  Typography,
  Card,
  InputAdornment,
  IconButton,
  FilledInput,
  FormHelperText,
} from '@mui/material';
import { Box } from '@mui/system';

import ScreenHandler from './ScreenHandler';
import { IErrorsSignup } from './signup.type';
import { NavLink } from 'react-router';
import { FormikTouched } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export interface ISignup {
  values: IErrorsSignup;
  errors: IErrorsSignup;
  touched: FormikTouched<{
    passwordRepeat: string | undefined;
    lastName: string | undefined;
    firstName: string | undefined;
    email: boolean;
    password: boolean;
  }>;
  handleChange: <T>(t: T) => void;
  handleBlur: <T>(t: T) => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
  /* and other goodies */
}

const SignupComponent = (props: ISignup) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = props;

  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const handleVisible = () => {
    setPasswordVisible(!passwordVisible);
  };

  const formError = errors.errorForm;
  const firstNameError =
    errors.firstName && touched.firstName && errors.firstName;
  const lastNameError = errors.lastName && touched.lastName && errors.lastName;
  const emailError = errors.email && touched.email && errors.email;
  const passwordError = errors.password && touched.password && errors.password;
  const passwordRepeat =
    errors.passwordRepeat && touched.passwordRepeat && errors.passwordRepeat;

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 2,
        padding: 2,
      }}
    >
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            marginBottom: 4,
          }}
        >
          <Typography component="h1" variant="h4">
            Регистрация
          </Typography>
        </Box>
        <Box
          sx={{
            marginBottom: 2,
          }}
        >
          <FormLabel error={!!formError}>{formError}</FormLabel>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormLabel htmlFor="email">Имя</FormLabel>
            <TextField
              type="firstName"
              name="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              placeholder="Имя"
              id="firstName"
              error={!!firstNameError}
              helperText={firstNameError}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormLabel htmlFor="email">Фамилия</FormLabel>
            <TextField
              type="lastName"
              name="lastName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              placeholder="Фамилия"
              id="lastName"
              error={!!lastNameError}
              helperText={lastNameError}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormLabel htmlFor="email">Email</FormLabel>
            <TextField
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Email"
              id="email"
              error={!!emailError}
              helperText={emailError}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
            }}
          >
            <FormLabel htmlFor="password">Пароль</FormLabel>
            <FilledInput
              type={passwordVisible ? 'text' : 'password'}
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              placeholder="Пароль"
              id="password"
              error={!!passwordError}
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      passwordVisible
                        ? 'hide the password'
                        : 'display the password'
                    }
                    onClick={handleVisible}
                    edge="end"
                  >
                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Box
              sx={{
                marginLeft: 2,
              }}
            >
              <FormHelperText id="password" error={!!passwordError}>
                {passwordError}
              </FormHelperText>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
            }}
          >
            <FormLabel htmlFor="passwordRepeat">Проверка пароля</FormLabel>
            <FilledInput
              type={passwordVisible ? 'text' : 'password'}
              name="passwordRepeat"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.passwordRepeat}
              placeholder="Пароль"
              id="passwordRepeat"
              error={!!passwordRepeat}
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      passwordVisible
                        ? 'hide the password'
                        : 'display the password'
                    }
                    onClick={handleVisible}
                    edge="end"
                  >
                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Box
              sx={{
                marginLeft: 2,
              }}
            >
              <FormHelperText id="password" error={!!passwordRepeat}>
                {passwordRepeat}
              </FormHelperText>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: 2,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={isSubmitting}
            >
              Создать
            </Button>
          </Box>
          <Box
            sx={{
              marginTop: 2,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <NavLink to="/login" end>
              У меня уже есть аккаунт. Войти
            </NavLink>
          </Box>
        </Box>
      </form>
    </Card>
  );
};

export default ScreenHandler(SignupComponent);
