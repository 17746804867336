import { apiAlliance } from './constants';

interface IBriefcase {
  briefcaseName: string;
  briefcaseOwnerId: string;
  amount: number;
  currency: string;
  active: [];
  passive: [];
  _id: string;
  __v: string;
}

export const apiCreateBriefcase = (
  accToken: string,
  briefcaseName?: string,
) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${accToken}`);

  const raw = JSON.stringify({
    briefcaseName,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  };

  return fetch(`${apiAlliance}/briefcases`, requestOptions)
    .then(async (resp): Promise<IBriefcase[]> => resp.json())
    .catch((error) => {
      return error;
    });
};

export const apiGetBriefcaseById = (accToken: string) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${accToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  return fetch(`${apiAlliance}/briefcases/briefcase`, requestOptions)
    .then(async (resp): Promise<IBriefcase[]> => resp.json())
    .catch((error) => {
      return error;
    });
};
