import { apiAlliance } from './constants';
import {
  PurseHistoryActionTypeEnum,
  PurseHistoryActionNameEnum,
} from '../Screen/Home/Tabs/Briefcase/Briefcase.component';
import { getCookie } from 'typescript-cookie';

interface IReserveAmountAll {
  idNameLoan: string;
  loanAmount: number;
  reserveAmountId: string;
}

interface IHistory {
  amount: number;
  actionType: PurseHistoryActionTypeEnum;
  actionName: PurseHistoryActionNameEnum;
  dateCreate: string;
  _id: string;
}

export interface IPurse {
  _id: string;
  ownerId: string;
  amount: number;
  reserveAmount: number;
  currency: string;
  reserveAmountAll: IReserveAmountAll[];
  history: IHistory[];
}

export const apiGetPurseById = () => {
  const accToken = getCookie('accToken');
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${accToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  return fetch(`${apiAlliance}/purses/purse`, requestOptions)
    .then(async (resp): Promise<IPurse> => resp.json())
    .catch((error) => {
      return error;
    });
};
