import React from 'react';
import HomeHandler from './Home.handler';
import { Box } from '@mui/system';
import BriefcaseComponent from './Tabs/Briefcase/Briefcase.component';
import { IHomePathTab } from '../../widgets/navigateTabs/types';
import NavigateTabsComponent from '../../widgets/navigateTabs/navigateTabs.component';

export interface iHomeComponent {
  pathTabs: IHomePathTab[];
  activeTab: IHomePathTab;
}

const HomeComponent = (props: iHomeComponent) => {
  const { pathTabs, activeTab } = props;

  return (
    <Box>
      <NavigateTabsComponent pathTabs={pathTabs} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          borderRadius: 2,
        }}
      >
        {activeTab?.value === '/home/briefcase' && <BriefcaseComponent />}
      </Box>
    </Box>
  );
};

export default HomeHandler(HomeComponent);
