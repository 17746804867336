import React from 'react';
import HomeComponent from './Home.component';
import { Container } from '@mui/system';

const AccountScreen = () => {
  return (
    <Container maxWidth="md">
      <HomeComponent />
    </Container>
  );
};

export default AccountScreen;
