import { createSlice } from '@reduxjs/toolkit';
import { IPurse } from '../../api/purse';

export interface IPurseState {
  purses: IPurse[];
}

const initialState: IPurseState = {
  purses: [],
};

export const purseSlice = createSlice({
  name: 'purses',
  initialState,
  reducers: {
    setPurse: (state, action) => {
      state.purses = [action.payload];
    },
  },
});

export const { setPurse } = purseSlice.actions;

export default purseSlice.reducer;
