import { LoansStatusEnum } from './constants';

export const getStatusLoan = (status: LoansStatusEnum) => {
  let text = '';
  switch (LoansStatusEnum[status]) {
    case LoansStatusEnum.new:
      text = 'Новый';
      break;
    case LoansStatusEnum.approval:
      text = 'Одобрен';
      break;
    case LoansStatusEnum.agreement:
      text = 'Выбран';
      break;
    case LoansStatusEnum.success:
      text = 'Заключен';
      break;
    case LoansStatusEnum.closed:
      text = 'Закрыт';
      break;
    case LoansStatusEnum.old:
      text = 'Истек срок предложения';
      break;
    case LoansStatusEnum.blocked:
      text = 'Заблокирован';
      break;
  }

  return text;
};

export function getValueText(value: number) {
  return `${value}`;
}

export const getDayText = (num: number) => {
  const preLastDigit = (num % 100) / 10;

  if (preLastDigit === 1) {
    return 'дней';
  }

  switch (num % 10) {
    case 1:
      return 'день';
    case 2:
    case 3:
    case 4:
      return 'дня';
    default:
      return 'дней';
  }
};
