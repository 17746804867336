import { UserSignIn } from "../Interfice/user";
import { apiOctavian } from "./constants";

export const callUserToken = async (token: string) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  return fetch(`${apiOctavian}/users/token`, requestOptions).then((response) =>
    response.json(),
  );
};

export const callAuthSignin = async (data: UserSignIn) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
  };

  return fetch(`${apiOctavian}/auth/sign-in`, requestOptions).then((response) =>
    response.json(),
  );
};
