import React from 'react';
import AccountComponent from './Account.component';
import { Container } from '@mui/system';

const AccountScreen = () => {
  return (
    <Container maxWidth="md">
      <AccountComponent />
    </Container>
  );
};

export default AccountScreen;
