import React from "react";
import { useSelector } from "react-redux";
import { selectActiveUser } from "../../useSelector";
import { iAccountComponent } from "./Account.component";

const AccountHandler = (
  AccountComponent: React.ComponentType<iAccountComponent>,
) => {
  const WithScreen = () => {
    const activeUser = useSelector(selectActiveUser);

    return (
      <div>
        <AccountComponent activeUser={activeUser} />
      </div>
    );
  };

  WithScreen.displayName = `AccountHandler(${AccountComponent.displayName || AccountComponent.name})`;

  return WithScreen;
};

export default AccountHandler;
