import { Formik } from 'formik';
import React from 'react';
import { Button, FormLabel, TextField } from '@mui/material';
import { Box } from '@mui/system';
import * as Yup from 'yup';
import { IPurse } from '../../api/purse';

const initValueProposalForm = {
  percent: '',
};

interface IValueProposalForm {
  percent: string;
}

interface IErrorsProposalForm {
  percent?: string;
}

const ProposalFormValidate = (values: IValueProposalForm) => {
  const errors: IErrorsProposalForm = {};

  if (!values?.percent) {
    errors.percent = 'Обязательное поле';
  }

  return errors;
};

interface Props {
  purses: IPurse[];
  onAddProposal: (
    percent: string,
    setSubmitting: (setSubmitting: boolean) => void,
  ) => void;
}

const AddProposal = (props: Props) => {
  const validationSchema = Yup.object().shape({
    percent: Yup.number()
      .min(4, 'Минимум 4')
      .max(80, 'Максимум 80')
      .required('Обязательное поле')
      .typeError('только цифры'),
  });

  const purse = props.purses[0];

  return (
    <Formik
      initialValues={initValueProposalForm}
      validate={ProposalFormValidate}
      onSubmit={(formikValues, formikHelpers) => {
        props.onAddProposal(formikValues.percent, formikHelpers.setSubmitting);
      }}
      validateOnChange
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <FormLabel htmlFor="percent">Процент</FormLabel>
              {!!purse?.amount && (
                <FormLabel htmlFor="percent">
                  счет: {`${purse.amount} ${purse.currency}`}
                  {purse.reserveAmount
                    ? ` (Зарезервировано ${purse.reserveAmount})`
                    : ''}
                </FormLabel>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                <TextField
                  name="percent"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.percent}
                  placeholder="Процент"
                  id="percent"
                  disabled={isSubmitting}
                  error={!!errors.percent}
                  helperText={errors.percent}
                />
              </Box>
              <Button type="submit" disabled={isSubmitting}>
                Предложить
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </Formik>
  );
};

export default AddProposal;
