import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SignupValidate } from './signup.validate';
import { ISignupDate } from './signup.type';
import { signupSubmitReAction } from './reAction';
import { ISignup } from './Signup.component';

const initValueSignup = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordRepeat: '',
};
function signupHandler(SignupScreen: React.ComponentType<ISignup>) {
  const WithScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = (
      values: ISignupDate,
      formikHelpers: FormikHelpers<any>,
    ) => {
      signupSubmitReAction(dispatch, values, formikHelpers, navigate);
    };

    return (
      <Formik
        initialValues={initValueSignup}
        validate={SignupValidate}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <SignupScreen
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        )}
      </Formik>
    );
  };
  WithScreen.displayName = `SignupHandler(${SignupScreen.displayName || SignupScreen.name})`;
  return WithScreen;
}

export default signupHandler;
