import React from 'react';
import MarketsComponent from './Markets.component';
import { Container } from '@mui/system';
const MarketsScreen = () => {
  return (
    <Container maxWidth="md">
      <MarketsComponent />
    </Container>
  );
};

export default MarketsScreen;
