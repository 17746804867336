export enum LoansStatusEnum {
  new = 'new',
  old = 'old',
  blocked = 'blocked',
  approval = 'approval',
  agreement = 'agreement',
  success = 'success',
  closed = 'closed',
}

export enum LoansStatusSearchEnum {
  new = 'new',
  approval = 'approval',
  success = 'success',
}
