import { Button, ButtonGroup } from "@mui/material";
import React from "react";
import { IHomePathTab, IMarketsPathTab } from "./types";

interface Props {
  pathTabs: IMarketsPathTab[] | IHomePathTab[];
}

const NavigateTabsComponent = ({ pathTabs }: Props) => {
  return (
    <ButtonGroup variant="text" size="small" aria-label="Basic button group">
      {pathTabs.map((tab) => (
        <Button key={tab.value} onClick={tab.handleClick}>
          {tab.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default NavigateTabsComponent;
