import React from 'react';
import MainComponent from './Main.component';
import { Container } from '@mui/system';
const MainScreen = () => {
  return (
    <Container maxWidth="xl">
      <MainComponent />
    </Container>
  );
};

export default MainScreen;
