import { createSlice } from '@reduxjs/toolkit';
import { LoanType, paginateResponse } from '../../api/loans';

export interface iLoansInitState {
  loans: LoanType[];
  paginate?: paginateResponse;
}

const initialState: iLoansInitState = {
  loans: [],
  paginate: {
    limit: 0,
    totalDocs: 0,
    offset: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0,
  },
};

const loansSlice = createSlice({
  name: 'loans',
  initialState: initialState,
  reducers: {
    setLoans: (
      state,
      action: { payload: { loans: LoanType[]; paginate: paginateResponse } },
    ) => {
      state.loans = action.payload.loans;
      state.paginate = action.payload.paginate;
    },
    setOneLoan: (state, action) => {
      const loanFind = state.loans.find(
        (loan) => loan.idName === action.payload.idName,
      );
      if (!loanFind) {
        state.loans = [action.payload, ...state.loans];
      } else {
        state.loans = state.loans.map((loan) => {
          if (loan.idName === action.payload.idName) {
            return action.payload;
          }

          return loan;
        });
      }
    },
  },
});

export const { setLoans, setOneLoan } = loansSlice.actions;
export default loansSlice.reducer;
