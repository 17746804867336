import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/system';
import { FormControlLabel } from '@mui/material';
import { getStatusLoan } from '../../Components/Loans/utils';
import {
  LoansStatusEnum,
  LoansStatusSearchEnum,
} from '../../Components/Loans/constants';
import { LoansSearchType } from '../../Components/Loans/useHooks';

interface Props {
  loansSearch: LoansSearchType;
}

export default function CheckboxesLoans(props: Props) {
  const { loansSearch } = props;

  const handleSelectNew = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    loansSearch.handleParams(LoansStatusSearchEnum.new);
  };

  const handleSelectApproval = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    loansSearch.handleParams(LoansStatusSearchEnum.approval);
  };

  const handleSelectSuccess = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    loansSearch.handleParams(LoansStatusSearchEnum.success);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleSelectNew}
            checked={!!loansSearch.searchParam[LoansStatusEnum.new]}
          />
        }
        label={getStatusLoan(LoansStatusEnum.new)}
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleSelectApproval}
            checked={!!loansSearch.searchParam[LoansStatusEnum.approval]}
          />
        }
        label={getStatusLoan(LoansStatusEnum.approval)}
      />
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleSelectSuccess}
            checked={!!loansSearch.searchParam[LoansStatusEnum.success]}
          />
        }
        label={getStatusLoan(LoansStatusEnum.success)}
      />
    </Box>
  );
}
