import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCookie } from 'typescript-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveUser,
  selectLoans,
  selectLoansPaginate,
  selectPurse,
} from '../../useSelector';
import {
  apiAddLoan,
  apiAddProposalInLoans,
  apiAgreementLoan,
  apiGetByLoanId,
  apiGetLoans,
  ILoanCreateType,
  LoanProposalType,
} from '../../api/loans';
import { setLoans, setOneLoan } from '../../reducer/loans/loans';
import { ILoanDialogProps } from './LoanView.dialog';
import { ILoanCreateDialogProps } from './LoanCreate.dialog';
import { apiGetPurseById } from '../../api/purse';
import { setPurse } from '../../reducer/purses/purses';
import { LoansStatusEnum, LoansStatusSearchEnum } from './constants';

const sizePage = 10;
const initPage = 1;

export const useDialogView = () => {
  const [isLoanOpen, setIsLoanOpen] = React.useState(false);
  const [showFormAddProposal, setShowFormAddProposal] = React.useState(false);
  const [searchParams, setURLSearchParams] = useSearchParams();
  const openLoanId = searchParams.get('loanid');
  const accToken = getCookie('accToken');

  const loansSelect = useSelector(selectLoans);
  const loansPaginate = useSelector(selectLoansPaginate);
  const activeUser = useSelector(selectActiveUser);
  const pursesSelect = useSelector(selectPurse);
  const dispatch = useDispatch();

  const loans = React.useMemo(() => {
    return loansSelect.map((loan) => {
      return {
        ...loan,
        isSelfBorrower: loan.loanBorrowerId === activeUser.user?._id,
      };
    });
  }, [loansSelect, activeUser.user?._id]);

  const handleSetQueryLoanId = (loanId: string) => {
    if (loanId) {
      setURLSearchParams((nextInt) => {
        nextInt.set('loanid', loanId);
        return nextInt;
      });
    }
  };

  React.useEffect(() => {
    if (openLoanId && accToken) {
      apiGetByLoanId(accToken, openLoanId).then((LoanDate) => {
        if (LoanDate?._id) {
          dispatch(setOneLoan(LoanDate));
          setIsLoanOpen(true);
        }
      });
    }
  }, [openLoanId, accToken, dispatch]);

  const handleAddProposal = (
    percent: string,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    const proposal: LoanProposalType = {
      percent: Number(percent),
    };
    if (openLoanId) {
      apiAddProposalInLoans(openLoanId, proposal)
        .then((updLoan) => {
          if (updLoan._id) {
            dispatch(setOneLoan(updLoan));
            setShowFormAddProposal(false);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const isSelfLoan = React.useMemo(() => {
    const loanFind = loans.find((loan) => loan.idName === openLoanId);
    const isSelfLoan = loanFind?.loanBorrowerId === activeUser.user?._id;
    return isSelfLoan;
  }, [loans, openLoanId, activeUser.user?._id]);

  const handleAgreementLoan = React.useCallback(async () => {
    if (openLoanId) {
      apiAgreementLoan(openLoanId).then((updLoan) => {
        if (updLoan._id) {
          dispatch(setOneLoan(updLoan));
        }
      });
    }
  }, [openLoanId, dispatch]);

  const loanDialog: ILoanDialogProps = {
    openLoanId,
    isLoanOpen,
    purses: pursesSelect,
    handleClose: () => {
      setURLSearchParams((nextInt) => {
        nextInt.delete('loanid');
        return nextInt;
      });
      setIsLoanOpen(false);
      setShowFormAddProposal(false);
    },
    handleAddProposal,
    handleAgreementLoan,
    isSelfLoan,
    showFormAddProposal,
    handleShowFormAddProposal: () => {
      apiGetPurseById().then((purse) => {
        if (purse._id) {
          dispatch(setPurse(purse));
        }
      });
      setShowFormAddProposal(true);
    },
    handleHiddenFormAddProposal: () => {
      setShowFormAddProposal(false);
    },
  };

  return {
    loanDialog,
    loans,
    handleSetQueryLoanId,
    paginate: loansPaginate,
  };
};

export const useLoanCreateDialog = () => {
  const [isLoanCreateOpen, setIsLoanCreateOpen] = React.useState(false);
  const dispatch = useDispatch();

  const loanCreateDialog: ILoanCreateDialogProps = {
    isLoanCreateOpen,
    handleOpenLoanCreate: () => {
      setIsLoanCreateOpen(true);
    },
    handleCloseLoanCreate: () => {
      setIsLoanCreateOpen(false);
    },
    handleSubmit: (
      newLoan: ILoanCreateType,
      setSubmitting: (setSubmitting: boolean) => void,
    ) => {
      apiAddLoan({
        activityPeriod: newLoan.activityPeriod,
        loanAmount: Number(newLoan.loanAmount),
        loanTerm: Number(newLoan.loanTerm),
        paymentInterval: newLoan.paymentInterval,
      })
        .then((createdLoan) => {
          if (createdLoan.idName) {
            dispatch(setOneLoan(createdLoan));
          }
        })
        .finally(() => {
          setIsLoanCreateOpen(false);
          setSubmitting(false);
        });
    },
  };

  return {
    loanCreateDialog,
  };
};

export type LoansSearchType = {
  handleParams: (status: LoansStatusSearchEnum) => void;
  searchParam: {
    new?: string | null;
    approval?: string | null;
    success?: string | null;
  };
  onChangePage: (event: React.ChangeEvent<unknown>, page: number) => void;
};

export const useLoansSearch = (): LoansSearchType => {
  const [searchParams, setURLSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const searchLoansNew = searchParams.get(LoansStatusEnum.new);
  const searchLoansApproval = searchParams.get(LoansStatusEnum.approval);
  const searchLoansSuccess = searchParams.get(LoansStatusEnum.success);

  const searchParam = useMemo(() => {
    return {
      new: searchLoansNew,
      approval: searchLoansApproval,
      success: searchLoansSuccess,
    };
  }, [searchLoansNew, searchLoansApproval, searchLoansSuccess]);

  useEffect(() => {
    setURLSearchParams((nextInt) => {
      nextInt.set(LoansStatusEnum.new, '1');
      nextInt.set(LoansStatusEnum.approval, '1');
      return nextInt;
    });
  }, []);

  const handleParams = (status: LoansStatusSearchEnum) => {
    apiGetLoans(
      {
        page: initPage,
        limit: sizePage,
      },
      {
        ...searchParam,
        [LoansStatusSearchEnum[status]]: searchParam[status] ? null : '1',
      },
    ).then((loans) => {
      if (loans?.docs) {
        const paginate = { ...loans };
        paginate.docs = null;
        dispatch(setLoans({ loans: loans.docs, paginate }));
      }
    });

    if (searchParam[status]) {
      setURLSearchParams((nextInt) => {
        nextInt.delete(status);
        return nextInt;
      });
    } else {
      setURLSearchParams((nextInt) => {
        nextInt.set(status, '1');
        return nextInt;
      });
    }
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    apiGetLoans(
      {
        page: page,
        limit: sizePage,
      },
      searchParam,
    ).then((loans) => {
      if (loans?.docs?.length) {
        const paginate = { ...loans };
        paginate.docs = null;
        dispatch(setLoans({ loans: loans.docs, paginate }));
      }
    });
  };

  React.useEffect(() => {
    apiGetLoans(
      {
        page: initPage,
        limit: sizePage,
      },
      {
        [LoansStatusEnum.new]: '1',
        [LoansStatusEnum.approval]: '1',
      },
    ).then((loans) => {
      if (loans?.docs?.length) {
        const paginate = { ...loans };
        paginate.docs = null;
        dispatch(setLoans({ loans: loans.docs, paginate }));
      }
    });
    // eslint-disable-next-line
  }, [dispatch]);

  return {
    searchParam,
    handleParams,
    onChangePage: handleChangePage,
  };
};
