import { IErrors } from "./login.type";

export const loginValidate = (values: { email: string; password: any }) => {
  const errors: IErrors = {};
  if (!values.email) {
    errors.email = "Обязательно поле";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Не корректное названия адреса";
  }

  if (!values.password) {
    errors.password = "Обязательно поле";
  }

  return errors;
};
