import Signup from './Signup.component';
import React from 'react';
import { Container } from '@mui/system';

const SignupScreen = () => {
  return (
    <Container maxWidth="sm">
      <Signup />
    </Container>
  );
};

export default SignupScreen;
