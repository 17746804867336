import React, { useState } from 'react';
import { IConfirmComponent } from './Confirm.component';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { confirmAuth } from '../../api/confirm';

const ConfirmHandler = (
  ConfirmComponent: React.ComponentType<IConfirmComponent>,
) => {
  const WithScreen = () => {
    const [load, setLoad] = useState(false);
    const [message, setMessage] = useState('');
    const [isSuccess, setSuccess] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const service = searchParams.get('service');
    const action = searchParams.get('action');
    const confirmCode = searchParams.get('confirm-code');

    React.useEffect(() => {
      setLoad(true);
      if (service && action && confirmCode) {
        if (service === 'auth') {
          confirmAuth(action, confirmCode)
            .then((response) => {
              let messageText = '';
              if (response.statusCode === 400) {
                messageText = 'Произошла не известная ошибка';
                if (response.code === 'timeout_expired') {
                  messageText = 'Время действия ссылки истекло';
                }
                setMessage(messageText);
                setLoad(false);
                setSuccess(false);
                return;
              }
              if (response.statusCode >= 400) {
                messageText = 'Произошла не известная ошибка';
                setMessage(messageText);
                setLoad(false);
                setSuccess(false);
                return;
              }
              if (action === 'confirm_email') {
                setMessage('email успешно подтвержден');
              }

              setLoad(false);
              setSuccess(true);
            })
            .catch(() => {
              setMessage('Произошла не известная ошибка');
              setLoad(false);
              setSuccess(false);
            });
        }
      }
    }, [service, action, confirmCode]);

    const handleContinue = () => {
      navigate('/account');
    };

    const handleGoToMain = () => {
      navigate('/');
    };

    return (
      <div>
        <ConfirmComponent
          load={load}
          message={message}
          isSuccess={isSuccess}
          handleContinue={handleContinue}
          handleGoToMain={handleGoToMain}
        />
      </div>
    );
  };

  WithScreen.displayName = `ConfirmHandler(${ConfirmComponent.displayName || ConfirmComponent.name})`;

  return WithScreen;
};

export default ConfirmHandler;
