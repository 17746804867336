import { apiOctavian } from './constants';
import { getCookie } from 'typescript-cookie';

export const confirmAuth = async (action: string, confirmCode: string) => {
  const accToken = getCookie('accToken');
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${accToken}`);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ confirmCode }),
  };

  return fetch(`${apiOctavian}/auth/${action}`, requestOptions).then(
    (response) => response.json(),
  );
};
