import { apiCreateUser } from '../../api/signup';
import { setActiveUser } from '../../reducer/user/activeUser';
import { ISignupDate } from './signup.type';
import { FormikHelpers } from 'formik/dist/types';
import { setCookie } from 'typescript-cookie';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router';
import { UserCreate } from '../../Interfice/user';

export const signupSubmitReAction = (
  dispatch: Dispatch<UnknownAction>,
  values: ISignupDate,
  { setSubmitting, setErrors }: FormikHelpers<any>,
  navigate: NavigateFunction,
) => {
  setErrors({});

  const signupData: UserCreate = {
    personal: {
      userName: {
        firstName: values.firstName,
        lastName: values.lastName,
      },
    },
    contacts: {
      email: {
        value: values.email,
      },
    },
    password: values.password,
  };

  apiCreateUser(signupData)
    .then((response) => {
      let formError = '';
      if (typeof response.token === 'string') {
        dispatch(setActiveUser(response));
        setCookie('accToken', response.token, { expires: 365 });
        navigate('/home');
      } else if (response.statusCode >= 400 && response.statusCode <= 499) {
        formError = 'Ошибка создания формы';
      } else if (response.statusCode >= 500) {
        formError = 'Ошибка сервера. Попробуйте позже';
      } else {
        formError = 'Не известная ошибка. Попробуйте позже';
      }

      setErrors({ formError });
    })
    .catch(() => {
      setErrors({ formError: 'Не известная ошибка. Попробуйте позже' });
    })
    .finally(() => {
      setSubmitting(false);
    });
};
