import React from 'react';
import { Dialog } from '@mui/material';
import LoanView, { ILoanViewProps } from './LoanView';

export interface ILoanDialogProps extends ILoanViewProps {
  handleClose: () => void;
}
const LoanViewDialog = (props: ILoanDialogProps) => {
  const {
    handleClose,
    isLoanOpen,
    openLoanId,
    loan,
    handleShowFormAddProposal,
    handleHiddenFormAddProposal,
    showFormAddProposal,
    handleAddProposal,
    isSelfLoan,
    handleAgreementLoan,
    purses,
  } = props;

  return (
    <Dialog onClose={handleClose} open={isLoanOpen}>
      <LoanView
        handleClose={handleClose}
        loan={loan}
        openLoanId={openLoanId}
        handleShowFormAddProposal={handleShowFormAddProposal}
        handleHiddenFormAddProposal={handleHiddenFormAddProposal}
        showFormAddProposal={showFormAddProposal}
        handleAddProposal={handleAddProposal}
        isSelfLoan={isSelfLoan}
        handleAgreementLoan={handleAgreementLoan}
        purses={purses}
        isLoanOpen={isLoanOpen}
      />
    </Dialog>
  );
};

export default LoanViewDialog;
