import React from 'react';
import AccountHandler from './Account.handler';
import { IActiveUser } from '../../reducer/user/activeUser';
import { Avatar, Card, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { red } from '@mui/material/colors';

export interface iAccountComponent {
  activeUser: IActiveUser;
}

const AccountComponent = (props: iAccountComponent) => {
  const isLogin = props.activeUser.login;
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: 1, borderRadius: 2 }}
    >
      <Box>
        <Typography component="h1" variant="h4">
          Аккаунт
        </Typography>
      </Box>
      {!isLogin && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            borderRadius: 2,
            justifyContent: 'space-evenly',
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      {isLogin && (
        <Card
          variant="outlined"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              borderRadius: 2,
              p: 4,
            }}
          >
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {`${props.activeUser.user?.personal.userName.firstName?.[0]} ${props.activeUser.user?.personal.userName.lastName?.[0]}`}
            </Avatar>
            <Box>
              <Typography component="div">
                ФИО:{' '}
                {`${props.activeUser.user?.personal.userName.firstName} ${props.activeUser.user?.personal.userName.lastName}`}
              </Typography>
              <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                Email: {props.activeUser.user?.contacts.email.value}
              </Typography>
              <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                Подтверждения email:{' '}
                {props.activeUser.user?.contacts.email.confirm
                  ? 'Подтвержден'
                  : 'Не подтвержден'}
              </Typography>
            </Box>
          </Box>
        </Card>
      )}
    </Box>
  );
};

export default AccountHandler(AccountComponent);
