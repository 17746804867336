import React from 'react';
import MarketsHandle from './Markets.handle';
import { IMarketsPathTab } from '../../widgets/navigateTabs/types';
import { Box } from '@mui/system';
import NavigateTabsComponent from '../../widgets/navigateTabs/navigateTabs.component';
import LoansComponent from '../../Components/Loans/Loans.component';
export interface MarketsComponentProps {
  pathTabs: IMarketsPathTab[];
  activeTab: IMarketsPathTab;
}
const MarketsComponent = ({ pathTabs, activeTab }: MarketsComponentProps) => {
  return (
    <Box>
      <NavigateTabsComponent pathTabs={pathTabs} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          borderRadius: 2,
        }}
      >
        {activeTab?.value === '/markets/loans' && <LoansComponent />}
      </Box>
    </Box>
  );
};

export default MarketsHandle(MarketsComponent);
