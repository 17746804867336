import { apiAlliance } from './constants';

interface ISubMetrics {
  name: string;
  value?: string;
}

interface IJsMessage {
  name?: string;
  message?: string;
  stack?: string;
  fileName?: string;
}
interface IRadarData {
  name: string;
  radarType: string;
  subMetrics?: ISubMetrics[];
  jsMessage?: IJsMessage;
  userAgent?: string;
}

export const apiRadar = async (radarData: IRadarData) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(radarData),
  };

  return fetch(`${apiAlliance}/radar`, requestOptions)
    .then((response) => response.json())
    .catch((_) => {});
};
