import React from 'react';
import { MainScreenProps } from './Main.component';
import { useSelector } from 'react-redux';
import { selectActiveUser } from '../../useSelector';
const MainHandle = (MainScreen: React.ComponentType<MainScreenProps>) => {
  const WithScreen = () => {
    const activeUser = useSelector(selectActiveUser);
    return <MainScreen activeUser={activeUser} />;
  };

  WithScreen.displayName = `MainHandle(${MainScreen.displayName || MainScreen.name})`;

  return WithScreen;
};

export default MainHandle;
