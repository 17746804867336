import { IErrorsSignup } from './signup.type';

export const SignupValidate = (values: IErrorsSignup) => {
  const errors: IErrorsSignup = {};

  if (!values?.firstName) {
    errors.firstName = 'Обязательное поле';
  }

  if (!values?.lastName) {
    errors.lastName = 'Обязательное поле';
  }

  if (!values?.email) {
    errors.email = 'Обязательное поле';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Не корректное названия адреса';
  }

  if (!values.password) {
    errors.password = 'Обязательное поле';
  }
  if (!values.passwordRepeat) {
    errors.passwordRepeat = 'Обязательное поле';
  }

  if (
    values.password &&
    values.passwordRepeat &&
    values.password !== values.passwordRepeat
  ) {
    errors.passwordRepeat = 'Пароли не совпадают';
  }

  return errors;
};
