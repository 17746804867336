import React from 'react';
import {
  TextField,
  Button,
  FormLabel,
  Typography,
  Card,
  InputAdornment,
  IconButton,
  FormHelperText,
  FilledInput,
} from '@mui/material';

import ScreenHandler from './Screen.handler';
import { Box } from '@mui/system';
import { NavLink } from 'react-router';
import { FormikTouched } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export interface ILogin {
  values: {
    email: string;
    password: string;
  };
  errors: any;
  touched: FormikTouched<{
    email: boolean;
    password: boolean;
  }>;
  handleChange: <T>(t: T) => void;
  handleBlur: <T>(t: T) => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
}

const LoginComponent = (props: ILogin) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = props;

  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const handleVisible = () => {
    setPasswordVisible(!passwordVisible);
  };

  const formError = errors.formError;
  const emailError = errors.email && touched.email && errors.email;
  const passwordError = errors.password && touched.password && errors.password;

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 2,
        padding: 2,
      }}
    >
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            marginBottom: 4,
          }}
        >
          <Typography component="h1" variant="h4">
            Войти
          </Typography>
        </Box>
        <Box
          sx={{
            marginBottom: 2,
          }}
        >
          <FormLabel error={!!formError}>{formError}</FormLabel>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormLabel htmlFor="email">Email</FormLabel>
            <TextField
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="email"
              id="email"
              error={!!emailError}
              helperText={emailError}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
            }}
          >
            <FormLabel htmlFor="password">Ваш пароль</FormLabel>
            <FilledInput
              type={passwordVisible ? 'text' : 'password'}
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              placeholder="пароль"
              id="password"
              error={!!passwordError}
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      passwordVisible
                        ? 'hide the password'
                        : 'display the password'
                    }
                    onClick={handleVisible}
                    edge="end"
                  >
                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Box
              sx={{
                marginLeft: 2,
              }}
            >
              <FormHelperText id="password" error={!!passwordError}>
                {passwordError}
              </FormHelperText>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: 2,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={isSubmitting}
            >
              Войти
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <NavLink to="/signup" end>
            Создать новый аккаунт
          </NavLink>
        </Box>
      </form>
    </Card>
  );
};

export default ScreenHandler(LoginComponent);
