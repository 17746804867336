import { Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { IBriefcase } from '../../../../reducer/briefcases/briefcase';
import BriefcaseHandler from './Briefcase.handler';
import { IPurse } from '../../../../api/purse';
import Purse from './Purse';

export enum PurseHistoryActionTypeEnum {
  plus = 'plus',
  minus = 'minus',
}

export enum PurseHistoryActionNameEnum {
  loan = 'loan',
  commission = 'commission',
  debt = 'debt',
}

export interface IBriefcaseComponentProps {
  briefcases: IBriefcase[];
  purses: IPurse[];
}

const BriefcaseComponent = (props: IBriefcaseComponentProps) => {
  const purse = props.purses[0];
  return (
    <>
      <Typography component="h1" variant="h4">
        Портфель
      </Typography>
      {props.briefcases.map((briefcase) => {
        const purse = props.purses.find(
          (purse) => purse._id === briefcase.purseId,
        );

        return (
          <Card
            key={briefcase._id}
            variant="outlined"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              borderRadius: 2,
              p: 1,
            }}
          >
            <Typography component="div">
              Название: {briefcase.briefcaseName}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                Валюта: {purse?.currency}
              </Typography>
              <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                Счет: {purse?.amount}{' '}
                {purse?.reserveAmount
                  ? ` (Зарезервировано ${purse?.reserveAmount})`
                  : ''}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                Активы: {briefcase.active.length}
              </Typography>
              <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                Пассивы: {briefcase.passive.length}
              </Typography>
            </Box>
          </Card>
        );
      })}
      {purse && <Purse purse={purse} />}
    </>
  );
};

export default BriefcaseHandler(BriefcaseComponent);
