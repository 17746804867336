import React from "react";
import { MarketsComponentProps } from "./Markets.component";
import useNavigateTabs from "../../widgets/navigateTabs/useNavigateTabs";
import { marketsPathTabsData } from "../../widgets/navigateTabs/constants/marketsPathTabsDate";
import { IMarketsPathTab } from "../../widgets/navigateTabs/types";

const MarketsHandle = (
  MarketsComponentProps: React.ComponentType<MarketsComponentProps>,
) => {
  const WithScreen = () => {
    const { pathTabs, activeTab } = useNavigateTabs(
      marketsPathTabsData,
    ) as unknown as {
      pathTabs: IMarketsPathTab[];
      activeTab: IMarketsPathTab;
    };

    return <MarketsComponentProps pathTabs={pathTabs} activeTab={activeTab} />;
  };

  WithScreen.displayName = `MarketsHandler(${MarketsComponentProps.displayName || MarketsComponentProps.name})`;

  return WithScreen;
};

export default MarketsHandle;
