import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { IActiveUser, removeActiveUser } from "../../reducer/user/activeUser";
import { NavigateFunction } from "react-router";
import { IHeaderLinks } from "./header.type";
import { removeCookie } from "typescript-cookie";

export const getHeaderLinks = (
  dispatch: Dispatch<UnknownAction>,
  activeuser: IActiveUser,
  navigate: NavigateFunction,
) => {
  const headerLinks: IHeaderLinks = [
    {
      value: "/main",
      label: "Главное",
      handleChange: function () {
        navigate(this.value);
      },
    },
  ];

  if (activeuser.token) {
    headerLinks.push(
      {
        value: "/home",
        label: "Портфель",
        handleChange: function () {
          navigate(this.value);
        },
      },
      {
        value: "/markets",
        label: "Рынок",
        handleChange: function () {
          navigate(this.value);
        },
      },
      {
        value: "/account",
        label: "Аккаунт",
        handleChange: function () {
          navigate(this.value);
        },
      },
      {
        value: "/login",
        label: "Выйти",
        handleChange: function () {
          removeCookie("accToken");
          dispatch(removeActiveUser());
          navigate("/main");
        },
      },
    );
  }

  if (!activeuser.token) {
    headerLinks.push({
      value: "/login",
      label: "Войти",
      handleChange: function () {
        navigate(this.value);
      },
    });
  }

  return headerLinks;
};
