import React from "react";
import { iHomeComponent } from "./Home.component";
import { homePathTabsData } from "../../widgets/navigateTabs/constants/homePathTabsDate";
import useNavigateTabs from "../../widgets/navigateTabs/useNavigateTabs";
import { IHomePathTab } from "../../widgets/navigateTabs/types";

const HomeHandler = (HomeComponent: React.ComponentType<iHomeComponent>) => {
  const WithScreen = () => {
    const { pathTabs, activeTab } = useNavigateTabs(
      homePathTabsData,
    ) as unknown as {
      pathTabs: IHomePathTab[];
      activeTab: IHomePathTab;
    };

    return (
      <div>
        <HomeComponent pathTabs={pathTabs} activeTab={activeTab} />
      </div>
    );
  };

  WithScreen.displayName = `HomeHandler(${HomeComponent.displayName || HomeComponent.name})`;

  return WithScreen;
};

export default HomeHandler;
