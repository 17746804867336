import React from 'react';
import { Outlet } from 'react-router';
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Snackbar,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import HeaderHandler from './Header.handler';
import { IHeaderLinks } from './header.type';

export interface IHeader {
  headerLinks: IHeaderLinks;
  pathname: string;
  snackbarError?: string;
  handleCloseSnackbarError: () => void;
}

function HeaderComponent(props: IHeader) {
  const { headerLinks, pathname, snackbarError, handleCloseSnackbarError } =
    props;
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Paper
        sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 100 }}
        elevation={2}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {!isMobile && (
            <Typography
              component="p"
              sx={{ margin: 'auto', flexGrow: 1, marginLeft: 5 }}
            >
              FilinUp
            </Typography>
          )}
          <BottomNavigation
            sx={{ flexGrow: 9 }}
            showLabels
            value={pathname}
            onChange={(event, newValue) => {
              const headerLink = headerLinks.find(
                ({ value }) => value === newValue,
              );
              headerLink?.handleChange();
            }}
          >
            {headerLinks.map(({ value, label }) => (
              <BottomNavigationAction key={value} label={label} value={value} />
            ))}
          </BottomNavigation>
          {!isMobile && (
            <Typography
              component="div"
              sx={{
                margin: 'auto',
                flexGrow: 1,
                marginRight: 5,
                width: '50px',
              }}
            />
          )}
        </Box>
      </Paper>
      <Box sx={{ height: 56 }} />
      <Box
        sx={{
          flex: 'auto',
          backgroundColor: grey[50],
          paddingTop: isMobile ? '20px' : '40px',
          paddingBottom: isMobile ? '20px' : '40px',
          paddingLeft: isMobile ? '20px' : '40px',
          paddingRight: isMobile ? '20px' : '40px',
        }}
      >
        <Outlet />
      </Box>
      <Paper sx={{ bottom: 0, left: 0, right: 0 }} elevation={2}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
          }}
        >
          <a href="mailto:filin-up@mail.ru">email: filin-up@mail.ru</a>
        </Box>
      </Paper>
      <Snackbar
        open={!!snackbarError}
        autoHideDuration={6000}
        onClose={handleCloseSnackbarError}
        message={snackbarError}
      />
    </Box>
  );
}

export default HeaderHandler(HeaderComponent);
