import { useLocation, useNavigate } from "react-router";
import React from "react";

function useNavigateTabs(pathTabsData: { value: string; label: string }[]) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const pathTabs = React.useMemo(() => {
    return pathTabsData.map((tab) => {
      const handleClick = function (value: string) {
        navigate(value);
      }.bind(tab, tab.value);

      return {
        value: tab.value,
        label: tab.label,
        handleClick,
        isActive: tab.value === pathname,
      };
    });
  }, [navigate, pathname, pathTabsData]);

  const activeTab = React.useMemo(() => {
    const activeTab = pathTabs.filter((tab) => tab.isActive);

    return (activeTab.length && activeTab[0]) || pathTabs[0];
  }, [pathTabs]);

  return {
    pathTabs,
    activeTab,
  };
}

export default useNavigateTabs;
