import { Button, Card, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { getDayText, getStatusLoan } from './utils';
import AddProposal from './AddProposal';
import { LoansStatusEnum } from './constants';
import React from 'react';
import { IPurse } from '../../api/purse';
import { LoanType } from '../../api/loans';

export interface ILoanViewProps {
  handleClose: () => void;
  isLoanOpen: boolean;
  openLoanId: string | null;
  showFormAddProposal: boolean;
  isSelfLoan: boolean;
  purses: IPurse[];
  handleAgreementLoan: () => Promise<void>;
  loan?: LoanType;
  handleAddProposal: (
    percent: string,
    setSubmitting: (setSubmitting: boolean) => void,
  ) => void;
  handleShowFormAddProposal: () => void;
  handleHiddenFormAddProposal: () => void;
}

const LoanView = (props: ILoanViewProps) => {
  const {
    openLoanId,
    loan,
    handleShowFormAddProposal,
    handleHiddenFormAddProposal,
    showFormAddProposal,
    handleAddProposal,
    isSelfLoan,
    handleAgreementLoan,
    purses,
    handleClose,
  } = props;
  const proposals = (loan?.proposals && [...loan.proposals]) || [];

  proposals?.sort((a, b) => {
    if (a.percent < b.percent) {
      return -1;
    } else if (a.percent > b.percent) {
      return 1;
    }
    return 0;
  });

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 2,
        p: 1,
      }}
    >
      {!loan && <CircularProgress color="inherit" />}
      {loan && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Typography component="div">Названия: {openLoanId}</Typography>
            <Typography component="div">
              {getStatusLoan(loan.status)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
              Сумма займа: {loan.loanAmount} {loan.currency}
            </Typography>
            <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
              Процент: {loan.amountPercentage}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
              Срок погашения: {loan.loanTerm} {getDayText(loan.loanTerm)}
            </Typography>
            <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
              Срок предложения: {loan.activityPeriod}{' '}
              {getDayText(loan.activityPeriod)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
              Интервал погашения: {loan.paymentInterval}
            </Typography>
          </Box>
          {!!proposals.length && !showFormAddProposal && (
            <div>
              <Typography component="div">
                текущие предложения в процентах
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  gap: 2,
                }}
              >
                {proposals.map((proposal, index) => {
                  if (index >= 10) {
                    return null;
                  }
                  return (
                    <Typography
                      key={proposal._id}
                      sx={{ color: 'text.secondary', fontSize: 14 }}
                    >
                      {proposal.percent}%
                    </Typography>
                  );
                })}
              </Box>
            </div>
          )}

          {showFormAddProposal && (
            <AddProposal onAddProposal={handleAddProposal} purses={purses} />
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <Button
              onClick={
                !showFormAddProposal ? handleClose : handleHiddenFormAddProposal
              }
            >
              Назад
            </Button>{' '}
            {!showFormAddProposal &&
              !isSelfLoan &&
              (loan.status === LoansStatusEnum.approval ||
                loan.status === LoansStatusEnum.new) && (
                <Button onClick={handleShowFormAddProposal}>
                  Добавить предложения
                </Button>
              )}
            {!showFormAddProposal &&
              isSelfLoan &&
              loan.status === LoansStatusEnum.approval && (
                <Button onClick={handleAgreementLoan}>Получить займ</Button>
              )}
          </Box>
        </>
      )}
    </Card>
  );
};

export default LoanView;
