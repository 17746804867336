import { UserCreate } from '../Interfice/user';
import { apiOctavian } from './constants';

export const apiCreateUser = async (user: UserCreate) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(user),
  };

  return fetch(`${apiOctavian}/users`, requestOptions).then((response) =>
    response.json(),
  );
};
