import React from 'react';
import {
  Button,
  Card,
  Dialog,
  FormControl,
  FormLabel,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import { Box } from '@mui/system';
import * as Yup from 'yup';
import { ILoanCreateType } from '../../api/loans';
import { getDayText, getValueText } from './utils';

interface ILoanValue {
  loanAmount: number;
  loanTerm: number;
  paymentInterval: number;
  activityPeriod: number;
}

interface ILoanFormError {
  loanAmount?: string;
  loanTerm?: string;
  paymentInterval?: string;
  activityPeriod?: string;
}

const initValueLoanForm = {
  loanAmount: 1000,
  loanTerm: 30,
  paymentInterval: 1,
  activityPeriod: 3,
};

const loansFormValidate = (values: ILoanValue) => {
  const errors: ILoanFormError = {};

  if (!values?.loanAmount) {
    errors.loanAmount = 'Обязательное поле';
  }
  if (!values?.loanTerm) {
    errors.loanTerm = 'Обязательное поле';
  }

  if (!values?.paymentInterval) {
    errors.paymentInterval = 'Обязательное поле';
  }
  if (!values?.activityPeriod) {
    errors.activityPeriod = 'Обязательное поле';
  }

  return errors;
};

export interface ILoanCreateDialogProps {
  isLoanCreateOpen: boolean;
  handleOpenLoanCreate: () => void;
  handleCloseLoanCreate: () => void;
  handleSubmit: (
    newLoan: ILoanCreateType,
    setSubmitting: (setSubmitting: boolean) => void,
  ) => void;
}

const LoanCreateDialog = (props: ILoanCreateDialogProps) => {
  const { isLoanCreateOpen, handleSubmit, handleCloseLoanCreate } = props;

  const validationSchema = Yup.object().shape({
    loanAmount: Yup.number()
      .min(1000, 'Минимум 1000')
      .max(99999, 'Максимум 99999')
      .required('Обязательное поле')
      .typeError('только цифры'),
    loanTerm: Yup.number()
      .min(7, 'Минимум 7')
      .max(365, 'Максимум 365')
      .required('Обязательное поле')
      .typeError('только цифры'),
  });

  return (
    <Dialog
      open={isLoanCreateOpen}
      onClose={() => {
        handleCloseLoanCreate();
      }}
    >
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 2,
          p: 2,
        }}
      >
        <Formik
          initialValues={initValueLoanForm}
          validate={loansFormValidate}
          onSubmit={(formikValues, formikHelpers) => {
            handleSubmit(
              formikValues as ILoanCreateType,
              formikHelpers.setSubmitting,
            );
          }}
          validateOnChange
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <form onSubmit={handleSubmit}>
                <Typography component="h6" variant="h6">
                  Создание заявки на займ
                </Typography>
                <FormLabel htmlFor="percent">Валюта: RUB</FormLabel>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 1,
                    paddingTop: 2,
                  }}
                >
                  <FormControl fullWidth>
                    <FormLabel htmlFor="loanAmount">Сумма займа</FormLabel>
                    <TextField
                      name="loanAmount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.loanAmount}
                      placeholder="Сумма"
                      id="loanAmount"
                      disabled={isSubmitting}
                      error={!!errors.loanAmount}
                      helperText={errors.loanAmount}
                    />
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    paddingTop: 2,
                  }}
                >
                  <FormControl fullWidth>
                    <FormLabel htmlFor="loanTern">Срок погашения</FormLabel>
                    <Slider
                      name="loanTerm"
                      onChange={handleChange}
                      aria-label="loanTern"
                      value={values.loanTerm}
                      getAriaValueText={getValueText}
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={7}
                      max={365}
                    />
                    {`${values.loanTerm} ${getDayText(values.loanTerm)}`}
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel htmlFor="paymentInterval">
                      Интервал погашения
                    </FormLabel>
                    <Slider
                      id="paymentInterval"
                      name="paymentInterval"
                      onChange={handleChange}
                      aria-label="Интервал погашения"
                      value={values.paymentInterval}
                      getAriaValueText={getValueText}
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={1}
                      max={12}
                    />
                    {`${values.paymentInterval === 1 ? 'В конце срока' : `Поделить на ${values.paymentInterval} частей`}`}
                  </FormControl>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="loanTern">
                      Срок действия заявки в днях
                    </FormLabel>
                    <Slider
                      id="activityPeriod"
                      name="activityPeriod"
                      value={values.activityPeriod}
                      aria-label="Срок действия заявки в днях"
                      onChange={handleChange}
                      disabled={isSubmitting}
                      step={1}
                      marks
                      min={1}
                      max={3}
                    />
                    {`Предложения будет активно ${values.activityPeriod} ${getDayText(values.activityPeriod)}`}
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                    marginTop: 2,
                  }}
                >
                  <Button
                    onClick={handleCloseLoanCreate}
                    disabled={isSubmitting}
                  >
                    Назад
                  </Button>
                  <Button type="submit" disabled={isSubmitting}>
                    Создать
                  </Button>
                </Box>
              </form>
            </Box>
          )}
        </Formik>
      </Card>
    </Dialog>
  );
};
export default LoanCreateDialog;
