import Login from './Login.component';
import { Container } from '@mui/system';
import React from 'react';
const LoginScreen = () => {
  return (
    <Container maxWidth="sm">
      <Login />
    </Container>
  );
};

export default LoginScreen;
