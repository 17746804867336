import React from 'react';
import { blue } from '@mui/material/colors';
import { Box } from '@mui/system';
import { Grow, Typography, useMediaQuery } from '@mui/material';

import Carousel from 'react-material-ui-carousel';

import MainHandle from './Main.handle';

import SignupScreen from '../Signup';
import LoanView from '../../Components/Loans/LoanView';
import { LoansStatusEnum } from '../../Components/Loans/constants';
import Purse from '../Home/Tabs/Briefcase/Purse';

import { LoanType } from '../../api/loans';
import { IPurse } from '../../api/purse';

import {
  PurseHistoryActionNameEnum,
  PurseHistoryActionTypeEnum,
} from '../Home/Tabs/Briefcase/Briefcase.component';
import { IActiveUser } from '../../reducer/user/activeUser';

const loan: LoanType[] = [
  {
    _id: '67aa555a127d349f39e1822b',
    idName: 'ouurSGS20Z',
    loanBorrowerId: '67aa553d4594912251836900',
    loanAmount: 30000,
    currency: 'RUB',
    loanTerm: 30,
    paymentInterval: 1,
    activityPeriod: 2,
    status: LoansStatusEnum.approval,
    dateCreate: '2025-02-10T19:36:58.000Z',
    proposals: [
      {
        actionType: 'success',
        lenderId: '67aa55804594912251836912',
        percent: 29,
        reserveAmountId: '61fb0a0b-f810-46b7-b38c-2b1dfa7fb72f',
        _id: '67aa55e1127d349f39e18259',
      },
      {
        actionType: 'reject',
        lenderId: '67aa55f74594912251836927',
        percent: 28,
        reserveAmountId: '7b69e129-a301-4bc8-bd5a-650b09c52dfd',
        _id: '67aa5693127d349f39e1828a',
      },
    ],
    amountPercentage: 28,
  },
  {
    _id: '67aa5924b2421979fbb21ae7',
    idName: '0zXQAIbj2Y',
    loanBorrowerId: '67aa553d4594912251836900',
    loanAmount: 100000,
    currency: 'RUB',
    loanTerm: 60,
    paymentInterval: 3,
    activityPeriod: 3,
    status: LoansStatusEnum.approval,
    dateCreate: '2025-02-10T19:53:08.000Z',
    proposals: [
      {
        actionType: 'reject',
        lenderId: '67aa55804594912251836912',
        percent: 25,
        reserveAmountId: '08f84b6a-6646-484f-9f39-cfcab646f168',
        _id: '67aa5941bd2421979fbb21b02',
      },
      {
        actionType: 'success',
        lenderId: '67aa55f74594912251836927',
        percent: 24,
        reserveAmountId: '78c20cd8-0828-446d-a7d5-170631d99cad',
        _id: '67aa5958bf242197dfbb21b16',
      },
      {
        actionType: 'success',
        lenderId: '67aa55f74594912251836927',
        percent: 24.3,
        reserveAmountId: '78c20cd8-0828-446d-a7d5-170631d99cad',
        _id: '67aa5958b24d21979fbb21b16',
      },
    ],
    amountPercentage: 24,
  },
  {
    _id: '67aa5924b2akdj79fbb21ae7',
    idName: 'a0zXasAIaY',
    loanBorrowerId: '67aa553d4594912251836900',
    loanAmount: 5000,
    currency: 'RUB',
    loanTerm: 7,
    paymentInterval: 1,
    activityPeriod: 1,
    status: LoansStatusEnum.approval,
    dateCreate: '2025-02-10T19:53:08.000Z',
    proposals: [
      {
        actionType: 'reject',
        lenderId: '67aa55804594912251836912',
        percent: 3.5,
        reserveAmountId: '08f84b6a-6646-484f-9f39-cfcab646f168',
        _id: '67aa5941b24219d79fbb21b02',
      },
      {
        actionType: 'reject',
        lenderId: '67aa55804594912251836912',
        percent: 30,
        reserveAmountId: '08f84b6a-6646-484f-9f39-cfcab646f168',
        _id: '67aa5941b242s1979fbb21b02',
      },
      {
        actionType: 'success',
        lenderId: '67aa55f74594912251836927',
        percent: 31.1,
        reserveAmountId: '78c20cd8-0828-446d-a7d5-170631d99cad',
        _id: '67aa5958b2421979fabb21b16',
      },
    ],
    amountPercentage: 30,
  },
];

const purses: IPurse[] = [
  {
    _id: '67aa547483566asca9bce6cb',
    ownerId: '67aa545a45949122518368f5',
    amount: 20000,
    reserveAmount: 0,
    currency: 'RUB',
    reserveAmountAll: [],
    history: [
      {
        amount: 10000,
        actionType: PurseHistoryActionTypeEnum.plus,
        actionName: PurseHistoryActionNameEnum.loan,
        _id: '67aa58fb24x759c693282671',
        dateCreate: '2025-02-12T18:30:13.000Z',
      },
      {
        amount: 1000,
        actionType: PurseHistoryActionTypeEnum.minus,
        actionName: PurseHistoryActionNameEnum.loan,
        _id: '67aq5a4f24b759c69328267b',
        dateCreate: '2025-02-12T18:30:13.000Z',
      },
      {
        amount: 10000,
        actionType: PurseHistoryActionTypeEnum.plus,
        actionName: PurseHistoryActionNameEnum.commission,
        _id: '67aa5a4f24br59c69328267b',
        dateCreate: '2025-02-12T18:30:13.000Z',
      },
      {
        amount: 1000,
        actionType: PurseHistoryActionTypeEnum.minus,
        actionName: PurseHistoryActionNameEnum.commission,
        _id: '67aa5a4f242759c69328267b',
        dateCreate: '2025-02-12T18:30:13.000Z',
      },
    ],
  },
  {
    _id: '67aa547483566a9ca9bce6cb',
    ownerId: '67aa545a45949122518368f5',
    amount: 50000,
    reserveAmount: 0,
    currency: 'RUB',
    reserveAmountAll: [],
    history: [
      {
        amount: 10000,
        actionType: PurseHistoryActionTypeEnum.minus,
        actionName: PurseHistoryActionNameEnum.loan,
        _id: '67aa58fb24i759c693282671',
        dateCreate: '2025-02-12T18:30:13.000Z',
      },
      {
        amount: 5000,
        actionType: PurseHistoryActionTypeEnum.plus,
        actionName: PurseHistoryActionNameEnum.debt,
        _id: '67aa5a4r24b759c69328267b',
        dateCreate: '2025-02-12T18:30:13.000Z',
      },
      {
        amount: 4900,
        actionType: PurseHistoryActionTypeEnum.plus,
        actionName: PurseHistoryActionNameEnum.debt,
        _id: '67aa5a4f24b75gc69328267b',
        dateCreate: '2025-02-12T18:30:13.000Z',
      },
    ],
  },
  {
    _id: '67aa547483561a9ca9bce6cb',
    ownerId: '67aa545a45949122518368f5',
    amount: 50000,
    reserveAmount: 0,
    currency: 'RUB',
    reserveAmountAll: [],
    history: [
      {
        amount: 24000,
        actionType: PurseHistoryActionTypeEnum.minus,
        actionName: PurseHistoryActionNameEnum.loan,
        _id: '67aa58fb24b759c693242671',
        dateCreate: '2025-02-12T18:30:13.000Z',
      },
      {
        amount: 7100,
        actionType: PurseHistoryActionTypeEnum.plus,
        actionName: PurseHistoryActionNameEnum.debt,
        _id: '67aa58fb24b759c69f282671',
        dateCreate: '2025-02-12T18:30:13.000Z',
      },
      {
        amount: 90000,
        actionType: PurseHistoryActionTypeEnum.minus,
        actionName: PurseHistoryActionNameEnum.loan,
        _id: '67aa58fb24b759l693282671',
        dateCreate: '2025-02-12T18:30:13.000Z',
      },
      {
        amount: 2400,
        actionType: PurseHistoryActionTypeEnum.plus,
        actionName: PurseHistoryActionNameEnum.debt,
        _id: '67aa5a4f24b759a69328267b',
        dateCreate: '2025-02-12T18:30:13.000Z',
      },
      {
        amount: 20000,
        actionType: PurseHistoryActionTypeEnum.plus,
        actionName: PurseHistoryActionNameEnum.debt,
        _id: '67aa5a4f24bs59c69328267b',
        dateCreate: '2025-02-12T18:30:13.000Z',
      },
    ],
  },
];

const noob = () => {};
const noobPromise = () => Promise.resolve();

export interface MainScreenProps {
  activeUser?: IActiveUser;
}
const MainComponent = (props: MainScreenProps) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 2,
        // p: 1,
      }}
    >
      <Typography component="h1" variant="h4">
        Платформа частных займов
      </Typography>
      <Typography component="p">
        Наша платформа объединяет людей, которые хотят взять займ, и тех, кто
        готов дать деньги под выгодный процент. Без банков, без скрытых комиссий
        — просто, быстро и безопасно.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: 2,
          justifyContent: 'space-between',
        }}
      >
        <Grow
          in
          style={{
            transformOrigin: '0 0 0',
            backgroundColor: blue[50],
            borderRadius: '8px',
            padding: '8px',
          }}
          timeout={1000}
        >
          <Box>
            <Typography component="h2" variant="h5">
              Преимущества для кредиторов
            </Typography>
            <Typography component="p">
              Зарабатывайте в среднем 28.6% годовых на своих сбережениях.
            </Typography>
            <Typography component="p">
              Выбирайте заёмщиков с проверенной кредитной историей.
            </Typography>
            <Typography component="p">
              Полная прозрачность и контроль над своими инвестициями.
            </Typography>
          </Box>
        </Grow>
        <Grow
          in
          style={{
            transformOrigin: '0 0 0',
            backgroundColor: blue[50],
            borderRadius: '8px',
            padding: '8px',
          }}
          timeout={1500}
        >
          <Box>
            <Typography component="h2" variant="h5" align="right">
              Платформа частных займов
            </Typography>
            <Typography component="p" align="right">
              Получите займ под низкий процент без посредников.
            </Typography>
            <Typography component="p" align="right">
              Получите множество предложении и выберите лучшее предложение.
            </Typography>
            <Typography component="p" align="right">
              Гибкие условия погашения.
            </Typography>
          </Box>
        </Grow>
      </Box>
      <Grow
        in
        style={{
          transformOrigin: '0 0 0',
        }}
        timeout={3000}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: 2,
            justifyContent: 'space-around',
            height: isMobile ? 700 : 400,
          }}
        >
          <Carousel
            interval={2000}
            sx={{
              width: isMobile ? null : 400,
              height: 'min-content',
            }}
            animation="slide"
            duration={1000}
          >
            <LoanView
              handleClose={() => {}}
              loan={loan[0]}
              openLoanId={loan[0].idName}
              handleShowFormAddProposal={noob}
              handleHiddenFormAddProposal={noob}
              showFormAddProposal={false}
              handleAddProposal={noob}
              isSelfLoan={false}
              handleAgreementLoan={noobPromise}
              purses={[]}
              isLoanOpen={true}
            />
            <LoanView
              handleClose={() => {}}
              loan={loan[1]}
              openLoanId={loan[1].idName}
              handleShowFormAddProposal={noob}
              handleHiddenFormAddProposal={noob}
              showFormAddProposal={false}
              handleAddProposal={noob}
              isSelfLoan={true}
              handleAgreementLoan={noobPromise}
              purses={[]}
              isLoanOpen={true}
            />
            <LoanView
              handleClose={() => {}}
              loan={loan[2]}
              openLoanId={loan[2].idName}
              handleShowFormAddProposal={noob}
              handleHiddenFormAddProposal={noob}
              showFormAddProposal={true}
              handleAddProposal={(_, handle) => {
                handle(false);
              }}
              isSelfLoan={false}
              handleAgreementLoan={noobPromise}
              purses={purses}
              isLoanOpen={true}
            />
          </Carousel>
          <Carousel
            interval={2500}
            sx={{ width: isMobile ? null : 400, height: 'min-content' }}
            animation="slide"
            duration={1000}
          >
            <Purse purse={purses[0]} />
            <Purse purse={purses[1]} />
            <Purse purse={purses[2]} />
          </Carousel>
        </Box>
      </Grow>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: 2,
          justifyContent: 'space-between',
        }}
      >
        <Grow
          in
          style={{
            transformOrigin: '0 0 0',
            backgroundColor: blue[50],
            borderRadius: '8px',
            padding: '8px',
          }}
          timeout={3000}
        >
          <Box>
            <Typography component="h2" variant="h5">
              Как это работает
            </Typography>
            <Typography component="p">
              <b> Регистрация</b>: Создайте аккаунт за 2 минуты.
            </Typography>
            <Typography component="p">
              <b>Размещение заявки</b>: Укажите сумму и срок займа.
            </Typography>
            <Typography component="p">
              <b>Сделка</b>: Найдите подходящего кредитора или заёмщика.
            </Typography>
            <Typography component="p">
              <b>Перевод денег</b>: Безопасно и быстро.
            </Typography>
          </Box>
        </Grow>
        <Grow
          in
          style={{
            transformOrigin: '0 0 0',
            backgroundColor: blue[50],
            borderRadius: '8px',
            padding: '8px',
          }}
          timeout={2500}
        >
          <Box>
            <Typography component="h2" variant="h5" align="right">
              Безопасность
            </Typography>
            <Typography component="p" align="right">
              Все сделки защищены юридически.
            </Typography>
            <Typography component="p" align="right">
              Проверка кредитной истории заёмщиков.
            </Typography>
            <Typography component="p" align="right">
              Конфиденциальность ваших данных.
            </Typography>
          </Box>
        </Grow>
      </Box>
      {!props?.activeUser?.login && <SignupScreen />}
    </Box>
  );
};

export default MainHandle(MainComponent);
