import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { getCookie, removeCookie } from 'typescript-cookie';
import { callUserToken } from '../../api/auth';
import { setActiveUser } from '../../reducer/user/activeUser';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveUser } from '../../useSelector';
import { IHeader } from './Header.component';
import { getHeaderLinks } from './utils';

const HeaderHandler = (HeaderComponent: React.ComponentType<IHeader>) => {
  const WithScreen = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const activeUser = useSelector(selectActiveUser);
    const [snackbarError, setSnackbarError] = React.useState('');
    const handleCloseSnackbarError = () => {
      setSnackbarError('');
    };

    React.useEffect(() => {
      const accToken = getCookie('accToken');

      if (accToken && !activeUser.login) {
        setSnackbarError('');
        callUserToken(accToken).then((response) => {
          let authError = '';
          if (typeof response._id === 'string') {
            dispatch(setActiveUser({ user: response, token: accToken }));
            return;
          }
          if (response.statusCode >= 403) {
            authError = 'Ошибка авторизации';
            return;
          }

          if (response.statusCode >= 400) {
            removeCookie('accToken');
            authError = 'Потеря авторизации';
            navigate('/login');
          }
          if (response.statusCode >= 500) {
            authError = 'Ошибка сервера. Ошибка авторизации';
          }
          setSnackbarError(authError);
        });
      }
    }, [activeUser.login, dispatch, navigate]);

    const headerLinks = React.useMemo(() => {
      return getHeaderLinks(dispatch, activeUser, navigate);
    }, [dispatch, activeUser, navigate]);

    const getCurrentPathname = () => {
      if (pathname === '/') {
        return headerLinks[0].value;
      }
      return pathname;
    };

    return (
      <div>
        <HeaderComponent
          headerLinks={headerLinks}
          pathname={getCurrentPathname()}
          snackbarError={snackbarError}
          handleCloseSnackbarError={handleCloseSnackbarError}
        />
      </div>
    );
  };

  return WithScreen;
};

export default HeaderHandler;
