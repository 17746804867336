import { createSlice } from '@reduxjs/toolkit';
export interface IBriefcase {
  _id: string;
  briefcaseName: string;
  briefcaseOwnerId: string;
  purseId: string;
  amount: number;
  currency: number;
  active: [];
  passive: [];
  __v: 0;
}

export interface IBriefcases {
  briefcases: IBriefcase[];
}

const initialState: IBriefcases = {
  briefcases: [],
};

export const briefcasesSlice = createSlice({
  name: 'briefcases',
  initialState,
  reducers: {
    setBriefcases: (state, action) => {
      state.briefcases = action.payload;
    },
    removeBriefcase: (state, action) => {
      const briefcases = state.briefcases.filter((briefcase) => {
        return action.payload._id === briefcase._id;
      });

      state.briefcases = briefcases;
    },
  },
});

export const { setBriefcases, removeBriefcase } = briefcasesSlice.actions;

export default briefcasesSlice.reducer;
